import React from "react";
import { NavLink } from "react-router-dom";
import "./header.css";
function Navigation() {
    return (
        <nav>
            <ul>
                <li>
                    <NavLink to="/fave" className={({ isActive }) =>
                        (+isActive ? "selectedlink" : "notselectedlink")
                    }>Current Fave</NavLink>
                </li>
                <li>
                    <NavLink to="/archive" className={({ isActive }) =>
                        (+isActive ? "selectedlink" : "notselectedlink")
                    }>Archive</NavLink>
                </li>
                <li>
                    <NavLink to="/how" className={({ isActive }) =>
                        (+isActive ? "selectedlink" : "notselectedlink")
                    }>How This Works</NavLink>
                </li>
            </ul>
        </nav>
    );
}

function Header() {
    return (
        <section className="header">
            <h1 className="LargeH1">Invest With Knowledge!</h1>
            <Navigation />
        </section>
    );
}

export default Header;
