import React, { useEffect } from "react";

import "./body.css";
import "./fave.css";
import whyImage from "../why.jpg";
import "./why.css";

function How() {
    useEffect(() => {
        document.title = "Invest With Knowledge! - How This Works";
    }, []);

    return (
        <section className="mainbody">
            <h2 className="responsiveheader">How This Works</h2>
            <section className="whywrapper">
                <section>
                    <p>
                        "The early Greeks used to sit around for days and debate about how
                        many teeth a horse has. They thought they could figure it out just
                        by sitting there, instead of checking the horse. A lot of investors
                        sit around and debate whether a stock is going up, as if the
                        financial muse will give them the answer, instead of checking the
                        company."
                    </p>
                    <p>-Peter Lynch</p>
                    <section className="leftsection">
                        <img src={whyImage} alt="Josiah Swanson, Investing"></img>
                    </section>
                    <section className="rightsection">
                        <p>
                            Josiah Swanson, the creator of this website, believes in delayed
                            gratification, and his whole goal with Invest With
                            Knowledge! is to help people to see that good companies, over
                            time, perform well!
                        </p>
                    </section>
                    <section className="anotherrightsection">
                        <p>
                            Here at Invest With Knowledge!, we promote checking the company.
                            Specifically, we promote the discovery of valuable assets that are
                            typically ignored by Wall Street because they sound boring and
                            weird.
                        </p>
                        <h3>Benjamin Graham & The Intelligent Investor</h3>
                        <p>
                            Inspired by the founder of value investing, Benjamin Graham,
                            we base our entire stock selection process on his book,
                            <span>The Intelligent Investor</span>. The book is pretty long
                            and can be confusing at times, but chapters fourteen and fifteen
                            elucidate a stock selection process for aspiring value
                            investors. So, in order for a stock to show up on this site, it must
                            fit the criteria outlined in those two chapters.

                        </p>
                        <h3>What We Look For:</h3>
                        <p>
                            First things first, we filter out all the stocks in the S&P 500. We'd
                            like to spend most of our research time on stocks that most people
                            won't pay attention to.
                        </p>
                        <p>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Next, we apply the criteria outlined by Graham in
                            chapters fourteen
                            and fifteen of <span>The Intelligent Investor.</span> Every stock
                            we report has (at least once) passed the tests given in one or both of the
                            chapters.
                        </p>
                        <h4>Chapter Fourteen Stocks</h4>
                        <p>
                            Chapter Fourteen describes a strategy for the "defensive investor." The
                            criteria are pretty conservative:
                        </p>
                        <p>
                            &nbsp;&nbsp;&nbsp;&nbsp;&#x2022;&nbsp;Adequate Size—The business must
                            be resilient to "vicissitudes" ($2B or more in revenue).
                        </p>
                        <p>
                            &nbsp;&nbsp;&nbsp;&nbsp;&#x2022;&nbsp;A Sufficiently Strong Financial
                            Condition—The business's
                            long-term debt should not exceed half its equity.
                        </p>
                        <p>
                            &nbsp;&nbsp;&nbsp;&nbsp;&#x2022;&nbsp;Dividend Record—There must be a
                            dividend provided.
                        </p>
                        <p>
                            &nbsp;&nbsp;&nbsp;&nbsp;&#x2022;&nbsp;Earnings Growth—There should
                            be some growth in per-share earnings in the past five years.
                        </p>
                        <p>
                            &nbsp;&nbsp;&nbsp;&nbsp;&#x2022;&nbsp;Moderate Price to Earnings Ratio—The PE ratio
                            should not be more than fifteen.
                        </p>
                        <p>
                            &nbsp;&nbsp;&nbsp;&nbsp;&#x2022;&nbsp;Moderate Ratio of Price to
                            Assets—The current price of the stock should not be more than
                            120% of the book value.
                        </p>
                        <h4>Chapter Fifteen Stocks</h4>
                        <p>
                            Chapter Fifteen describes a strategy for the "enterprising investor." The
                            criteria are relatively less conservative:
                        </p>
                        <p>
                            &nbsp;&nbsp;&nbsp;&nbsp;&#x2022;&nbsp;A Sufficiently Strong Financial
                            Condition—The business's
                            long-term debt should not exceed two-thirds its equity.
                        </p>
                        <p>
                            &nbsp;&nbsp;&nbsp;&nbsp;&#x2022;&nbsp;Earnings Stability—There should
                            be some earnings for the stock in each of the past five years.
                        </p>
                        <p>
                            &nbsp;&nbsp;&nbsp;&nbsp;&#x2022;&nbsp;Dividend Record—There must be a
                            dividend provided.
                        </p>
                        <p>
                            &nbsp;&nbsp;&nbsp;&nbsp;&#x2022;&nbsp;Earnings Growth—There should
                            be some current growth in per-share earnings.
                        </p>
                        <p>
                            &nbsp;&nbsp;&nbsp;&nbsp;&#x2022;&nbsp;Moderate Ratio of Price to
                            Assets—The current price of the stock should not be more than
                            120% of the book value.
                        </p>
                    </section>
                </section>
            </section>
        </section>
    );
}

export default How;
