import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import "./body.css";
import "./fave.css";

function Business() {
    const [isReady, setIsReady] = useState(false);
    const { ticker } = useParams();
    const [business, setBusiness] = useState({
        ticker: "ABCD",
        name: "Company Name",
        description: "Company Descripton",
        industry: "COMPANY INDUSTRY",
        currentPrice: 0.0,
        trailingPE: 0,
        threeYearPE: 0,
        longTermDebt: 0,
        equity: 0,
        earningsVelocity: 0,
        totalCash: 0,
        totalCashPerShare: 0,
        totalAssets: 0,
        tangibleAssets: 0,
        intangibleAssets: 0,
        priceToAssets: 0,
        debtToEquity: 0,
        dividendYield: 0.0,
        sharesOutstanding: 0,
        sentiment_range: 0,
        sentiment_avg: 0,
        sentiment_median: 0,
    });

    const [history, setHistory] = useState([]);

    const currentDate = new Date();

    let currentyear = currentDate.getFullYear();
    let currentmonth = currentDate.getMonth() + 1;
    let currentdate = currentDate.getDate();

    let lastMonth = new Date(
        currentyear + "/" + currentmonth + "/" + currentdate
    ); // Re-defined for the beginning of the day
    lastMonth.setMonth(currentDate.getMonth() - 1);
    lastMonth.setDate(1); // lastMonth and nextMonth should be synced so that right when the clock hits 0, the next "fave" is chosen
    let lastMonthString =
        "16-" +
        ((lastMonth.getMonth() + 1).toString().length === 1
            ? "0" +
            (lastMonth.getMonth() + 1).toString() +
            "-" +
            lastMonth.getFullYear()
            : (lastMonth.getMonth() + 1).toString() + "-" + lastMonth.getFullYear());

    useEffect(() => {
        window.scrollTo(0, 0);
        const ch14 = `https://investingfunctionstorage.blob.core.windows.net/alphavantage/stock_data_big_usa_${lastMonthString}.json`;
        const ch15 = `https://investingfunctionstorage.blob.core.windows.net/alphavantage/stock_data_small_usa_${lastMonthString}.json`;
        const faves_history = `https://investingfunctionstorage.blob.core.windows.net/alphavantage/historical_faves.json`;

        Promise.all([fetch(ch14), fetch(ch15), fetch(faves_history)])
            .then((responses) => {
                return Promise.all(
                    responses.map((response) => {
                        if (!response.ok) {
                            throw new Error("Failed to fetch response");
                        }
                        return response.json();
                    })
                );
            })
            .then((data) => {
                const stockdata = data[0];
                const morestockdata = data[1];
                const iwk_history = data[2];
                console.log(iwk_history)
                let combined = stockdata.concat(morestockdata);
                let randomNum = Math.floor(Math.random() * combined.length);
                let business = combined[randomNum];
                let currentBusiness = combined.find(function (stock) {
                    return stock.ticker.toLowerCase() === ticker;
                });
                if (currentBusiness) {
                    business = currentBusiness;
                }

                let currentHistory = iwk_history["stockdata"].find(function (stock) {
                    return stock.ticker.toLowerCase() === ticker;
                });
                if (currentHistory) {
                    setHistory(currentHistory.history)
                }

                setBusiness({
                    ticker: business.ticker,
                    name: business.name,
                    description: business.description,
                    industry: business.industry,
                    currentPrice: Math.round(business.currentPrice * 100) / 100,
                    trailingPE: Math.round(business.trailingPE * 100) / 100,
                    threeYearPE: Math.round(business.threeYearPE * 100) / 100,
                    longTermDebt: Math.round(business.longTermDebt),
                    equity: Math.round(business.equity),
                    earningsVelocity: Math.round(business.earningsVelocity * 100) / 100,
                    totalCash: Math.round(business.totalCash),
                    totalCashPerShare: Math.round(business.totalCashPerShare * 100) / 100,
                    totalAssets: Math.round(business.totalAssets),
                    tangibleAssets: Math.round(
                        business.totalAssets - business.intangibleAssets
                    ),
                    intangibleAssets: Math.round(business.intangibleAssets),
                    priceToAssets:
                        Math.round(
                            ((business.currentPrice * business.sharesOutstanding) /
                                (business.totalAssets - business.intangibleAssets)) *
                            100
                        ) / 100,
                    debtToEquity: Math.round(business.debtToEquity * 100) / 100,
                    dividendYield: Math.round(business.dividendYield * 10000) / 100,
                    sharesOutstanding: business.sharesOutstanding,
                    sentiment_range: Math.round(business.sentiment_range * 100) / 100,
                    sentiment_avg: Math.round(business.sentiment_avg * 100) / 100,
                    sentiment_median: Math.round(business.sentiment_median * 100) / 100,
                });
                setIsReady(true);
            })
            .catch((error) => {
                console.error("There was a problem with the fetch operation:", error);
            });

        document.title = `Invest With Knowledge! - ${business.name}`;
    }, []);

    const calculateColor = (value, type) => {
        if (type === "trailingPE" || type === "threeYearPE") {
            if (value <= 20) {
                const red = 52 + value * 5.8; // Good is 0, Mid is 20
                const green = 168;
                return `rgb(${red}, ${green}, 52)`;
            }
            if (value > 20) {
                const red = 168;
                const green = 284 + value * -5.8; // Mid is 20, Bad is 40
                return `rgb(${red}, ${green}, 52)`;
            }
        }
        if (
            type === "longTermDebt" ||
            type === "equity" ||
            type === "debtToEquity"
        ) {
            if (value <= 0.66) {
                const red = 52 + value * 175.76; // Good is 0, Mid is 0.66
                const green = 168;
                return `rgb(${red}, ${green}, 52)`;
            }
            if (value > 0.66) {
                const red = 168;
                const green = 200.71 + value * -49.57; // Mid is 0.66, Bad is 3
                return `rgb(${red}, ${green}, 52)`;
            }
        }
        if (type === "earningsVelocity") {
            if (value > 0) {
                const red = 168 + value * (-232 / 3); // Good is 1.5, Mid is 0
                const green = 168;
                return `rgb(${red}, ${green}, 52)`;
            }
            if (value <= 0) {
                const red = 168;
                const green = 168 + value * (232 / 3); // Mid is 0, Bad is -1.5
                return `rgb(${red}, ${green}, 52)`;
            }
        }
        if (type === "priceToAssets") {
            if (value < 1.2) {
                const red = -83.5714 + 165.7143 * value; // Good is 0.5, Mid is 1.2
                const green = 168;
                return `rgb(${red}, ${green}, 52)`;
            }
            if (value >= 1.2) {
                const red = 168;
                const green = 245.333 + value * -64.444; // Mid is 1.2, Bad is 3
                return `rgb(${red}, ${green}, 52)`;
            }
        }
    };

    function capitalizeFirstLetter(text) {
        return text.toLowerCase().replace(/(?:^|\s)\S/g, function (char) {
            return char.toUpperCase();
        });
    }

    const trailingPEColor = calculateColor(business.trailingPE, "trailingPE");
    const threeYearPEColor = calculateColor(business.threeYearPE, "threeYearPE");
    const longTermDebtColor = calculateColor(
        business.debtToEquity,
        "longTermDebt"
    );
    const equityColor = calculateColor(business.debtToEquity, "equity");
    const debtToEquityColor = calculateColor(
        business.debtToEquity,
        "debtToEquity"
    );
    const earningsVelocityColor = calculateColor(
        business.earningsVelocity,
        "earningsVelocity"
    );
    const priceToAssetsColor = calculateColor(
        business.priceToAssets,
        "priceToAssets"
    );

    return (
        <section className="mainbody">
            <h2 className="responsiveheader">Business Details</h2>
            <div className={`stockdiv ${isReady ? "fade-in" : ""}`}>
                <p className="centeredp">Name: {business.name}</p>
                <p>Industry: {capitalizeFirstLetter(business.industry)}</p>
                <p>Ticker: {business.ticker}</p>
                <p>Price: ${business.currentPrice}</p>
            </div>
            <div className={`stockdiv ${isReady ? "fade-in" : ""}`}>
                <p className="centeredp">Company Details</p>
                <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;Trailing PE:{" "}
                    <span>
                        <span style={{ color: trailingPEColor }}>
                            {business.trailingPE}
                        </span>
                    </span>
                </p>
                <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;Three Year PE:{" "}
                    <span>
                        <span style={{ color: threeYearPEColor }}>
                            {business.threeYearPE}
                        </span>
                    </span>
                </p>
                <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;Long Term Debt:{" "}
                    <span>
                        <span style={{ color: longTermDebtColor }}>
                            ${business.longTermDebt.toLocaleString("en-US")}
                        </span>
                    </span>
                </p>
                <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;Equity:{" "}
                    <span>
                        <span style={{ color: equityColor }}>
                            ${business.equity.toLocaleString("en-US")}
                        </span>
                    </span>
                </p>
                <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;Debt/Equity:{" "}
                    <span>
                        <span style={{ color: debtToEquityColor }}>
                            {business.debtToEquity}
                        </span>
                    </span>
                </p>
                <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;Earnings Velocity:{" "}
                    <span>
                        <span style={{ color: earningsVelocityColor }}>
                            {business.earningsVelocity}
                        </span>
                    </span>
                </p>
                <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;Total Assets: $
                    {business.totalAssets.toLocaleString("en-US")}
                </p>
                <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;Tangible Assets: $
                    {business.tangibleAssets.toLocaleString("en-US")}
                </p>
                <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;Price/Assets:{" "}
                    <span>
                        <span style={{ color: priceToAssetsColor }}>
                            {business.priceToAssets}
                        </span>
                    </span>
                </p>
                <p>&nbsp;&nbsp;&nbsp;&nbsp;Dividend Yield: {business.dividendYield}%</p>
                <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;Total Cash: $
                    {business.totalCash.toLocaleString("en-US")}
                </p>
                <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;Total Cash/Share: $
                    {business.totalCashPerShare}
                </p>
                <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;Shares Outstanding:{" "}
                    {business.sharesOutstanding.toLocaleString("en-US")}
                </p>
                <p>Description: {business.description}</p>
            </div>
            <div className={`stockdiv ${isReady && (history.length > 0) ? "fade-in" : ""}`}>
                <p className="centeredp">I.W.K. History</p>
                {history.reverse().map((item, index) => {
                    const getStyledText = (text) => {
                        if (text.includes("SUCCESS")) {
                            const parts = text.split("SUCCESS");
                            return (
                                <>
                                    {parts[0]}<span style={{ color: "#86ce27" }}>SUCCESS </span>{parts[1]}
                                </>
                            );
                        } else if (text.includes("FAIL")) {
                            const parts = text.split("FAIL");
                            return (
                                <>
                                    {parts[0]}<span style={{ color: "#cd6b3e" }}>FAIL </span>{parts[1]}
                                </>
                            );
                        } else {
                            return text;
                        }
                    };
                    return (
                        <div className="archivediv" key={index}>
                            <p className="centeredp">{item.date}</p>
                            <p className="centeredp">
                                Chapter 14 Analysis:<br></br>{getStyledText(item.detail["Chapter 14 Analysis"])}
                            </p>
                            <p className="centeredp">
                                Chapter 15 Analysis:<br></br>{getStyledText(item.detail["Chapter 15 Analysis"])}
                            </p>
                        </div>
                    );
                })}
            </div>
        </section>
    );
}

export default Business;
