import React from "react";
import { GoogleOAuthProvider } from "@react-oauth/google";
import "./App.css";
const clientId =
  "726943756935-01snhp9v0rahhs0ar0kn1d3l4sb4k8it.apps.googleusercontent.com";

function App() {
  return (
    <GoogleOAuthProvider clientId={clientId}>
      <section className="App"></section>
    </GoogleOAuthProvider>
  );
}

export default App;
