import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

import "./body.css";
import "./fave.css";

function Archive() {
    const [stockdata, setStockData] = useState([]);
    useEffect(() => {
        window.scrollTo(0, 0);
        fetch(
            "https://investingfunctionstorage.blob.core.windows.net/alphavantage/faves.json"
        )
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Failed to fetch response");
                }
                return response.json();
            })
            .then((data) => {
                let faves = data["stockdata"];
                let archive = [];
                for (let i = 1; i < faves.length; i++) {
                    archive = [
                        ...archive,
                        {
                            date: faves[i].date,
                            ticker: faves[i].ticker,
                            name: faves[i].name,
                            description: faves[i].description,
                            industry: faves[i].industry,
                            currentPrice: Math.round(faves[i].currentPrice * 100) / 100,
                            trailingPE: Math.round(faves[i].trailingPE * 100) / 100,
                            threeYearPE: Math.round(faves[i].threeYearPE * 100) / 100,
                            longTermDebt: Math.round(faves[i].longTermDebt),
                            equity: Math.round(faves[i].equity),
                            earningsVelocity:
                                Math.round(faves[i].earningsVelocity * 100) / 100,
                            totalCash: Math.round(faves[i].totalCash),
                            totalCashPerShare:
                                Math.round(faves[i].totalCashPerShare * 100) / 100,
                            totalAssets: Math.round(faves[i].totalAssets),
                            tangibleAssets: Math.round(
                                faves[i].totalAssets - faves[i].intangibleAssets
                            ),
                            intangibleAssets: Math.round(faves[i].intangibleAssets),
                            priceToAssets:
                                Math.round(
                                    ((faves[i].currentPrice * faves[i].sharesOutstanding) /
                                        (faves[i].totalAssets - faves[i].intangibleAssets)) *
                                    100
                                ) / 100,
                            debtToEquity: Math.round(faves[i].debtToEquity * 100) / 100,
                            dividendYield: Math.round(faves[i].dividendYield * 10000) / 100,
                            sharesOutstanding: faves[i].sharesOutstanding,
                            sentiment_range: Math.round(faves[i].sentiment_range * 100) / 100,
                            sentiment_avg: Math.round(faves[i].sentiment_avg * 100) / 100,
                            sentiment_median:
                                Math.round(faves[i].sentiment_median * 100) / 100,
                        },
                    ];
                }
                setStockData(archive);
            })
            .catch((error) => {
                console.error("There was a problem with the fetch operation:", error);
            });
        document.title = "Invest With Knowledge! - Archive";
    }, []);

    function capitalizeFirstLetter(text) {
        return text.toLowerCase().replace(/(?:^|\s)\S/g, function (char) {
            return char.toUpperCase();
        });
    }

    return (
        <section className="mainbody">
            <h2 className="responsiveheader">Archive</h2>
            {stockdata.map((fave, index) => {
                return (
                    <div className="archivediv" key={index}>
                        <p className="centeredp">{fave.date}</p>
                        <p>Name: {fave.name}</p>
                        <p>Industry: {capitalizeFirstLetter(fave.industry)}</p>
                        <p>Ticker: {fave.ticker}</p>
                        <p>Price: ${fave.currentPrice}</p>
                        <p className="centeredp">
                            <NavLink to={`/business/${fave.ticker.toLowerCase()}`}>
                                View More Details
                            </NavLink>
                        </p>
                    </div>
                );
            })}
        </section>
    );
}

export default Archive;
