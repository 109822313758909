import React from "react";
import "./body.css";
function Footer() {
    return (
        <section className="footerbody">
            <h3>Copyright Invest With Knowledge! 2024</h3>
        </section>
    );
}

export default Footer;
