import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./index.css";
import "../src/Pacifico-Regular.ttf";
import "../src/Quicksand-VariableFont_wght.ttf";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Header from "./components/header";
// import Why from './components/why';
import Fave from './components/fave';
import Business from './components/business';
// import Fundamentals from './components/fundamentals';
import Archive from './components/archive';
import How from './components/how';
// import Terms from './components/terms';
// import Policy from './components/policy';
// import Account from './components/account';
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Header />
    <App />
    <Routes>
      <Route path="/" element={<Navigate to="/fave" />} />
      <Route path="/fave" element={<Fave />} />
      <Route path="/archive" element={<Archive />} />
      <Route path="/business/:ticker" element={<Business />} />
      <Route path="/how" element={<How />} />
    </Routes>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
