import React, { useEffect, useState } from "react";

import Footer from "./footer";

import "./body.css";
import "./fave.css";

function Fave() {
    let [time, setTime] = useState(Date.now());
    let [lastUpdate, setLastUpdate] = useState("July 19, 1999");
    const [fave, setFave] = useState({
        ticker: "ABCD",
        name: "Company Name",
        description: "Company Descripton",
        industry: "COMPANY INDUSTRY",
        currentPrice: 0.0,
        trailingPE: 0,
        threeYearPE: 0,
        longTermDebt: 0,
        equity: 0,
        earningsVelocity: 0,
        totalCash: 0,
        totalCashPerShare: 0,
        totalAssets: 0,
        tangibleAssets: 0,
        intangibleAssets: 0,
        priceToAssets: 0,
        debtToEquity: 0,
        dividendYield: 0.0,
        sharesOutstanding: 0,
        sentiment_range: 0,
        sentiment_avg: 0,
        sentiment_median: 0,
    });

    const currentDate = new Date();

    let year = currentDate.getFullYear();
    let month = currentDate.getMonth() + 1;
    let date = currentDate.getDate();

    let nextMonth = new Date(year + "/" + month + "/" + date); // Re-defined for the beginning of the day
    let lastMonth = new Date(year + "/" + month + "/" + date); // Re-defined for the beginning of the day

    nextMonth.setMonth(currentDate.getMonth() + 1);
    nextMonth.setDate(1);
    let nextMonthTime = nextMonth.getTime();

    lastMonth.setMonth(currentDate.getMonth() - 1);
    lastMonth.setDate(1); // lastMonth and nextMonth should be synced so that right when the clock hits 0, the next "fave" is chosen
    let lastMonthString =
        (lastMonth.getMonth() + 1).toString().length === 1
            ? "0" +
            (lastMonth.getMonth() + 1).toString() +
            "-" +
            lastMonth.getFullYear()
            : (lastMonth.getMonth() + 1).toString() + "-" + lastMonth.getFullYear();
    useEffect(() => {
        const interval = setInterval(() => setTime(Date.now()), 1000);
        return () => {
            clearInterval(interval);
        };
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
        fetch(
            "https://investingfunctionstorage.blob.core.windows.net/alphavantage/faves.json"
        )
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Failed to fetch response");
                }
                return response.json();
            })
            .then((data) => {
                let dateUpdated = data["date_last_updated"];
                var dateParts = dateUpdated.split("-");
                var day = parseInt(dateParts[0]);
                var month = parseInt(dateParts[1]);
                var year = parseInt(dateParts[2]);
                var formattedDate = new Date(year, month - 1, day);
                var options = { year: "numeric", month: "long", day: "numeric" };
                setLastUpdate(formattedDate.toLocaleDateString(undefined, options));

                let stockdata = data["stockdata"];
                let randomNum = Math.floor(Math.random() * stockdata.length);
                let fave = stockdata[randomNum];
                let currentFave = stockdata.find(function (stock) {
                    return stock.date === lastMonthString;
                });
                if (currentFave) {
                    fave = currentFave;
                }
                setFave({
                    ticker: fave.ticker,
                    name: fave.name,
                    description: fave.description,
                    industry: fave.industry,
                    currentPrice: Math.round(fave.currentPrice * 100) / 100,
                    trailingPE: Math.round(fave.trailingPE * 100) / 100,
                    threeYearPE: Math.round(fave.threeYearPE * 100) / 100,
                    longTermDebt: Math.round(fave.longTermDebt),
                    equity: Math.round(fave.equity),
                    earningsVelocity: Math.round(fave.earningsVelocity * 100) / 100,
                    totalCash: Math.round(fave.totalCash),
                    totalCashPerShare: Math.round(fave.totalCashPerShare * 100) / 100,
                    totalAssets: Math.round(fave.totalAssets),
                    tangibleAssets: Math.round(fave.totalAssets - fave.intangibleAssets),
                    intangibleAssets: Math.round(fave.intangibleAssets),
                    priceToAssets:
                        Math.round(
                            ((fave.currentPrice * fave.sharesOutstanding) /
                                (fave.totalAssets - fave.intangibleAssets)) *
                            100
                        ) / 100,
                    debtToEquity: Math.round(fave.debtToEquity * 100) / 100,
                    dividendYield: Math.round(fave.dividendYield * 10000) / 100,
                    sharesOutstanding: fave.sharesOutstanding,
                    sentiment_range: Math.round(fave.sentiment_range * 100) / 100,
                    sentiment_avg: Math.round(fave.sentiment_avg * 100) / 100,
                    sentiment_median: Math.round(fave.sentiment_median * 100) / 100,
                });
            })
            .catch((error) => {
                console.error("There was a problem with the fetch operation:", error);
            });
        document.title = "Invest With Knowledge! - Current Fave";
    }, []);

    let secondstillmidnight = (nextMonthTime - time) / 1000;
    let daysleft = Math.floor(secondstillmidnight / 60 / 60 / 24);
    let hoursleft = Math.floor((secondstillmidnight / 60 / 60) % 24);
    let minutesleft = Math.floor((secondstillmidnight / 60) % 60);
    let secondsleft = Math.ceil(secondstillmidnight % 60);

    const calculateColor = (value, type) => {
        if (type === "trailingPE" || type === "threeYearPE") {
            if (value <= 20) {
                const red = 52 + value * 5.8; // Good is 0, Mid is 20
                const green = 168;
                return `rgb(${red}, ${green}, 52)`;
            }
            if (value > 20) {
                const red = 168;
                const green = 284 + value * -5.8; // Mid is 20, Bad is 40
                return `rgb(${red}, ${green}, 52)`;
            }
        }
        if (
            type === "longTermDebt" ||
            type === "equity" ||
            type === "debtToEquity"
        ) {
            if (value <= 0.66) {
                const red = 52 + value * 175.76; // Good is 0, Mid is 0.66
                const green = 168;
                return `rgb(${red}, ${green}, 52)`;
            }
            if (value > 0.66) {
                const red = 168;
                const green = 200.71 + value * -49.57; // Mid is 0.66, Bad is 3
                return `rgb(${red}, ${green}, 52)`;
            }
        }
        if (type === "earningsVelocity") {
            if (value > 0) {
                const red = 168 + value * (-232 / 3); // Good is 1.5, Mid is 0
                const green = 168;
                return `rgb(${red}, ${green}, 52)`;
            }
            if (value <= 0) {
                const red = 168;
                const green = 168 + value * (232 / 3); // Mid is 0, Bad is -1.5
                return `rgb(${red}, ${green}, 52)`;
            }
        }
        if (type === "priceToAssets") {
            if (value < 1.2) {
                const red = -83.5714 + 165.7143 * value; // Good is 0.5, Mid is 1.2
                const green = 168;
                return `rgb(${red}, ${green}, 52)`;
            }
            if (value >= 1.2) {
                const red = 168;
                const green = 245.333 + value * -64.444; // Mid is 1.2, Bad is 3
                return `rgb(${red}, ${green}, 52)`;
            }
        }
    };

    function capitalizeFirstLetter(text) {
        return text.toLowerCase().replace(/(?:^|\s)\S/g, function (char) {
            return char.toUpperCase();
        });
    }

    const trailingPEColor = calculateColor(fave.trailingPE, "trailingPE");
    const threeYearPEColor = calculateColor(fave.threeYearPE, "threeYearPE");
    const longTermDebtColor = calculateColor(fave.debtToEquity, "longTermDebt");
    const equityColor = calculateColor(fave.debtToEquity, "equity");
    const debtToEquityColor = calculateColor(fave.debtToEquity, "debtToEquity");
    const earningsVelocityColor = calculateColor(
        fave.earningsVelocity,
        "earningsVelocity"
    );
    const priceToAssetsColor = calculateColor(
        fave.priceToAssets,
        "priceToAssets"
    );

    return (
        <>
            <section className="mainbody">
                <h2 className="responsiveheader">Current Fave</h2>
                <section className="centeredp specialrightsection">
                    <p>
                        {daysleft} d: {hoursleft} h: {minutesleft} m: {secondsleft} s until
                        next fave
                    </p>
                </section>
                <div className="archivediv">
                    <p className="centeredp">Name: {fave.name}</p>
                    <p>Industry: {capitalizeFirstLetter(fave.industry)}</p>
                    <p>Ticker: {fave.ticker}</p>
                    <p>Price: ${fave.currentPrice}</p>
                </div>
                <div className="archivediv">
                    <p className="centeredp">Company Details</p>
                    <p>
                        &nbsp;&nbsp;&nbsp;&nbsp;Trailing PE:{" "}
                        <span>
                            <span style={{ color: trailingPEColor }}>{fave.trailingPE}</span>
                        </span>
                    </p>
                    <p>
                        &nbsp;&nbsp;&nbsp;&nbsp;Three Year PE:{" "}
                        <span>
                            <span style={{ color: threeYearPEColor }}>
                                {fave.threeYearPE}
                            </span>
                        </span>
                    </p>
                    <p>
                        &nbsp;&nbsp;&nbsp;&nbsp;Long Term Debt:{" "}
                        <span>
                            <span style={{ color: longTermDebtColor }}>
                                ${fave.longTermDebt.toLocaleString("en-US")}
                            </span>
                        </span>
                    </p>
                    <p>
                        &nbsp;&nbsp;&nbsp;&nbsp;Equity:{" "}
                        <span>
                            <span style={{ color: equityColor }}>
                                ${fave.equity.toLocaleString("en-US")}
                            </span>
                        </span>
                    </p>
                    <p>
                        &nbsp;&nbsp;&nbsp;&nbsp;Debt/Equity:{" "}
                        <span>
                            <span style={{ color: debtToEquityColor }}>
                                {fave.debtToEquity}
                            </span>
                        </span>
                    </p>
                    <p>
                        &nbsp;&nbsp;&nbsp;&nbsp;Earnings Velocity:{" "}
                        <span>
                            <span style={{ color: earningsVelocityColor }}>
                                {fave.earningsVelocity}
                            </span>
                        </span>
                    </p>
                    <p>
                        &nbsp;&nbsp;&nbsp;&nbsp;Total Assets: $
                        {fave.totalAssets.toLocaleString("en-US")}
                    </p>
                    <p>
                        &nbsp;&nbsp;&nbsp;&nbsp;Tangible Assets: $
                        {fave.tangibleAssets.toLocaleString("en-US")}
                    </p>
                    <p>
                        &nbsp;&nbsp;&nbsp;&nbsp;Price/Assets:{" "}
                        <span>
                            <span style={{ color: priceToAssetsColor }}>
                                {fave.priceToAssets}
                            </span>
                        </span>
                    </p>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;Dividend Yield: {fave.dividendYield}%</p>
                    <p>
                        &nbsp;&nbsp;&nbsp;&nbsp;Total Cash: $
                        {fave.totalCash.toLocaleString("en-US")}
                    </p>
                    <p>
                        &nbsp;&nbsp;&nbsp;&nbsp;Total Cash/Share: ${fave.totalCashPerShare}
                    </p>
                    <p>
                        &nbsp;&nbsp;&nbsp;&nbsp;Shares Outstanding:{" "}
                        {fave.sharesOutstanding.toLocaleString("en-US")}
                    </p>
                    <p>Description: {fave.description}</p>
                </div>
                <p className="centeredp">Stock data last updated on: {lastUpdate}</p>
                {/* <p>
                <span>
                    Please note that, while the "Daily Fave" is, in all likelihood, worth
                    taking a look at, it is fully at the User's discretion to make
                    investment decisions based off of what is found here.<br></br>All
                    business data comes from Alpha Vantage.<br></br>Companies are choosen
                    merely based off of value investing criteria given by Benjamin Graham
                    in chapter 15 of <span>The Intelligent Investor</span>.
                </span>
            </p> */}
            </section>
            <Footer />
        </>
    );
}

export default Fave;
